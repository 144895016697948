<template>
  <div>
    <b-card class="mb-5" v-if="!showForget">
      <h3 class="text-center mt-4 mb-4">{{$t('ACCOUNT.LINK_SENT')}}</h3>
      <div class="text-center">{{$t('ACCOUNT.LINK_SENT_INFO')}}</div>
    </b-card>
    <b-card class="forget-body mb-5" v-if="showForget">
      <h3 class="text-center mt-4 mb-4">{{$t('MEMBER.FORGET_PASSWORD')}}</h3>
      <div class="text-center">{{$t('ACCOUNT.ENTER_EMAIL')}}</div>
      <form class="forget-form" @submit.prevent="onSubmit">
        <b-alert
          :show="showWarning"
          variant="danger"
          dismissible
          @dismissed="showWarning = false"
          fade
          >{{$t('ACCOUNT.INVALID_EMAIL_PASSWORD')}}</b-alert
        >
        <b-form-input type="email" v-model="form.email" class="mb-12 mt-4" placeholder="E-post" />
        <div class="d-flex align-items-center justify-content-between mb-12 mt-12">
          <router-link :to="login_link" tag="a" class="line-link blue-link mr-4"
            >{{$t('ACCOUNT.TO_LOGIN')}}
          </router-link>
          <b-button class="btn-login" variant="primary" @click="onSubmit"
            >{{$t('MEMBER.RESET_PASSWORD')}}</b-button
          >
        </div>
      </form>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
.forget-body {
  max-width: 500px;
  width: 100%;
  .forget-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import store from '@/core/services/store';
import { set_session_customer_id } from '@/core/services/member.service';


export default {
  mixins: [validationMixin, toasts],
  name: 'forget',
  data() {
    return {
      showForget: true,
      // Remove this dummy login info
      form: {
        email: '',
        password: ''
      },
      showWarning: false,
      company_id: null,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  async mounted() {
    const data = await this.get_public_data();

    set_session_customer_id(data.customer_id);
    this.company_id = data.sid;
  },
  methods: {
    async get_public_data() {
      try {
        const res = await axios.get(`/company/public-data`);

        if (res.status === 200) {
          return res.data;
        }

      }
      catch (err) {
        console.error('get_public_data', err);
      }

      return null;
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.showWarning = true;
        return;
      }
      const email = this.$v.form.email.$model;
      axios
        .post('/user/forget', { email })
        .then(res => {
          if (res.status == 200)
            this.showForget = false;
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCOUNT.RESET'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCOUNT.UNABLE_RESET'));
        });
    },

  },
  computed: {
    login_link() {
      return '/ml-login';
    },

    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
